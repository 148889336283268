import React from "react";
import headshot from "../../images/community/headshot.jpeg";
import {Helmet} from "react-helmet";

const PolicyReport = () => {


    return (
        <div className="policy-report d-flex justify-content-center">
            <Helmet>
                <title>TRON Policy Report | TRONDAO</title>
                <meta name="description"
                      content="TRON DAO Policy Report Will Define Decentralization"/>
                <meta property="og:description" content="TRON DAO Policy Report Will Define Decentralization"/>
                <meta property="og:title" content="TRON Policy Report | TRONDAO"/>
            </Helmet>
            <div className="policy-report-inner d-flex flex-wrap justify-content-center">
                <div className="policy-report-inner-audio d-flex align-items-center">Audio Podcast</div>
                <h1 className="w-100">TRON POLICY REPORT</h1>
                <p>A conversation on the latest developments in cryptocurrency regulation. Our expert guests deliver
                    insights and analysis on the most current news and trends in crypto policy. Stay in the know and
                    ahead of the game with this must-listen podcast for crypto investors and blockchain
                    entrepreneurs.</p>
                <div className="policy-report-inner-divider"/>
                <div className="d-flex flex-wrap align-items-center policy-report-inner-row justify-content-center">
                    <div className="pt-3"><p>Meet Our Host!</p></div>
                    <div className="pt-3"><img src={headshot} className="img-fluid policy-report-inner-row-headshot"
                                               alt="David Uhryniak"/></div>
                    <div className="pt-3">
                        <strong>David Uhryniak</strong>
                        <p>Policy Lead, TRON DAO</p>
                    </div>
                    <div className="pt-3"><a href="https://tron-policy-report.castos.com/"
                                             id="community-policy-report-listen" target="_blank" rel="noreferrer">
                        <button>Listen to Full Episodes</button>
                    </a></div>
                </div>
            </div>

        </div>
    );
};


export default PolicyReport;


